function Footer() {
  return (
    <footer className='footer'>
      <div className='order'>
        <p>We're open from 12:00 to 22:00. Come visit us or order online.</p>
        <button className='btn'>Order Now</button>
      </div>
    </footer>
  );
}

export default Footer;
